import Image from '@components/Image';
import { Entry } from '@lib/features/entries';
import { PageHeaderProps } from './PageHeader';
import { PageHeaderRoot } from './PageHeaderRoot';

export const PageHeaderEvent = ({ view }: PageHeaderProps<Entry<'event'>>) => {
  const { entryImage, bannerImage } = view;
  const image = bannerImage || entryImage;
  return (
    <>
      <PageHeaderRoot
        afterGrid={
          image && (
            <Image
              alt={view.title ?? ''}
              ratio="landscapeWide"
              ShimProps={{ cx: { mT: 'lg' } }}
              rounded
              {...image}
            />
          )
        }
      />
    </>
  );
};
